import React from "react"
import "../styles/index.css"
import "../styles/utilities.css"
import "../styles/contact.css"
import "../styles/music.css"
import SEO from "../components/seo"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import cobdCover from "../images/cobd-album-cover.jpg"
import forJimmyDeluxeCover from "../images/for-jimmy-album-cover-deluxe.jpg"
import forJimmyRegularCover from "../images/for-jimmy-album-cover.jpg"
const Music = ({ data, location }) => {
  const title = "Musical Services"
  const description =
    "Listen to music by our talented church band, led by our minister Boy Golden. Find our albums on streaming services like Spotify, Soundcloud, Bandcamp and more..."

  return (
    <>
      <SEO title={title} description={description} />

      <div className="contact-page-container">
        <Navbar />
        <div className="content-container music  ">
          <h1 className="txt-white center-text txt-shadow">Church Tunes</h1>

          <div className="image-container">
            <div className="image-wrapper">
              <h3>Church of Better Daze</h3>
              <img src={cobdCover} alt="Church of Better Daze Album Cover" />
              <div className="button-container">
                <a
                  className="album-button"
                  target="_blank"
                  href="https://SixShooterRecords.lnk.to/COBDWE"
                >
                  Stream
                </a>
                <a
                  className="album-button"
                  target="_blank"
                  href="https://store.sixshooterrecords.com/collections/boy-golden/products/church-of-better-daze?variant=39350339502150"
                >
                  Purchase Vinyl/CD
                </a>
              </div>
            </div>
            <div className="image-wrapper">
              <h3>For Jimmy (EP)</h3>
              <img src={forJimmyRegularCover} alt="For Jimmy EP Cover" />
              <div className="button-container">
                <a
                  className="album-button"
                  target="_blank"
                  href="https://SixShooterRecords.lnk.to/jimmyWE"
                >
                  Stream
                </a>
                <a
                  className="album-button"
                  target="_blank"
                  href="https://store.sixshooterrecords.com/collections/boy-golden/products/pre-order-boy-golden-for-jimmy?variant=40602915176518"
                >
                  Purchase Vinyl/CD
                </a>
              </div>
            </div>
            <div className="image-wrapper">
              <h3>For Jimmy (Deluxe)</h3>
              <img
                src={forJimmyDeluxeCover}
                alt="For Jimmy Deluxe Album Cover"
              />
              <div className="button-container">
                <a
                  className="album-button"
                  target="_blank"
                  href="https://SixShooterRecords.lnk.to/forjimmydeluxeWE"
                >
                  Stream
                </a>
                <a
                  className="album-button"
                  target="_blank"
                  href="https://store.sixshooterrecords.com/collections/boy-golden/products/pre-order-boy-golden-for-jimmy?variant=40602990575686"
                >
                  Purchase Vinyl/CD
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Music
